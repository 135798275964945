import {V1} from '~/types/V1'

export class Api<SecurityDataType = unknown> extends V1<SecurityDataType> {
  static new() {
    return new Api()
  }

  constructor() {
    super()
    const token = useAuth().token
    //@ts-ignore
    this.instance.defaults.headers = {
      Authorization: `Bearer ${token.value}`,
      // accept: 'application/json',
    }
    // this.instance.defaults.baseURL = process.env.NUXT_PUBLIC_BACKEND_URL;
    this.instance.defaults.baseURL = '/api/proxy/api/'
    this.instance.interceptors.response.use(
      (response) => {
        return Promise.resolve(response)
      },
      (error) => {
        const status = error.response?.status ?? -1
        if (status === 404) {
          throw createError({
            statusCode: 404,
            statusMessage: '',
            fatal: true,
          })
        }
        if (status === 401 && !token.value) {
          throw error
        }
        if (status === 401) {
          token.value = null
          localStorage.removeItem('token')
          return useRouter().push('/login')
        }
        if ([401, 419].includes(status)) {
          // Token expired, refresh the token - called earlier
          // if (
          //   error.config.url !== '/v1/token/refresh' &&
          //   localStorage.getItem('token') &&
          //   !error.config._retry
          // ) {
          //   return this.refreshTokenAndRetry(error)
          // }

          localStorage.removeItem('token')
          return useRouter().push('/login')
        }

        if ([409].includes(status)) {
          useRouter().push('/verify-email')
        }

        if ([500].includes(status)) {
          console.error('[API Error]', error.data?.message, error.data)
        }
        if (status == 422) {
          return error.response.data
        }
        return Promise.reject(error.response);
      }
    )
  }

  async get<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R | any> {
    try {
      const response = await this.instance.get<T, R>(url, config)
      return response
    } catch (error) {
      console.error(`[GET ${url} Error]`, error)
      return error
    }
  }

  async post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<R | any> {
    try {
      const response = await this.instance.post<T, R>(url, data, config)
      return response
    } catch (error) {
      console.error(`[POST ${url} Error]`, error)
      return error
    }
  }
}
